import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { Ruhrjamb2024Service } from '../../../services/ruhrjamb2024.service';
import { Router} from '@angular/router';
import { Subscription } from '../../../models/subscription';
import { NotifyService } from '../../../services/notify.service';
import { ExportService } from '../../../services/export.service';


@Component({
  selector: 'app-ruhrjamb2024',
  templateUrl: './ruhrjamb2024.component.html',
  styleUrls: ['./ruhrjamb2024.component.css']
})
export class Ruhrjamb2024Component implements OnInit {

  event_id: any;
  registration_id: any;
  subscription_id: any;
  params: any;
  event:  any;
  stamm:  any;
  bezirk:  any;
  registration: any;
  progress: any;
  user: any;
  templatePages: any;
  status: any;
  templatePage: any;
  templatePage_id: any;
  subscription: any;
  subscription_fotoerlaubnis: any;
  leiter: any;
  tns: any;
  closed: any;
  button: any;

  sammelAnreiseOptions = [
    { name: 'Fahrrad', selected: false },
    { name: 'fußläufig', selected: false },
    { name: 'Reisebusse organisiert über die Stämme', selected: false },
    { name: 'ÖPNV', selected: false },
    { name: 'selbstständige An- und Abreise durch Teilnehmer', selected: false },
    { name: 'Nein', selected: false },
  ]

  sonderAnreiseOptions = [
    { name: 'Fahrrad', selected: false },
    { name: 'fußläufig', selected: false },
    { name: 'Reisebusse organisiert über die Stämme', selected: false },
    { name: 'ÖPNV', selected: false },
    { name: 'selbstständige An- und Abreise durch Teilnehmer', selected: false },
  ]

  constructor(
    private userService: UserService,
    private router: Router,
    private notifyService: NotifyService,
    private exportService: ExportService,
    private ruhrjamb2024Service: Ruhrjamb2024Service
  ) { }

  export() {
    return this.exportService.exportData(this.tns, 'csv');
}

AddOrUpdateSubscription(subscriptiondata) {
  this.subscription = new Subscription()
  this.subscription.data = subscriptiondata

  if (this.subscription_id !== undefined) {
    this.subscription.id = this.subscription_id
    this.ruhrjamb2024Service.updateSubscription(this.subscription)
      .subscribe(
        subscription => {
          this.notifyService.snotifySuccess("Anmeldung erfolgreich gespeichert!")
          this.router.navigate(['/events/custom/ruhrjamb2024/registrations'])
          .then(() => {
            window.location.reload();
          });
        },
        error => {
          this.notifyService.snotifyError("Anmeldung fehlerhaft!")
        }
      )
  } 
  else {
    this.ruhrjamb2024Service.addSubscription(this.subscription)
      .subscribe(
        subscription => {
          this.notifyService.snotifySuccess("Anmeldung erfolgreich angelegt!")
          this.router.navigate(['/events/custom/ruhrjamb2024/registrations']).then(() => {
            window.location.reload();
          });
        },
        error => {
          this.notifyService.snotifyError("Anmeldung fehlerhaft!")
        }
      )
  }
}

ngOnInit() {

  this.closed=0;

  this.subscription = new Subscription()
  this.subscription.sammelAnreise = null
  this.subscription.sonderAnreise = null

  this.event = new Array()
  this.event.title = "Ruhrjamb 2024"
  this.event.imageLink = "assets/images/ruhrjamb2024.png";
  this.event.active = 1;

  this.user = this.userService.getActualUser().subscribe(
    user => {
      this.user = user;
      this.user.id = this.user['id'];
      this.user.surname = this.user['surname'];
      this.user.givenname = this.user['givenname'];
      this.user.email = this.user['email'];
      this.user.stamm = this.user['stamm'];


      this.ruhrjamb2024Service.getSubscription().subscribe(
        data => {
          this.subscription = data;
          this.subscription_id = this.subscription['id'];

          this.ruhrjamb2024Service.getTNsofUser()
          .subscribe(data => {
            this.tns = data
          })

        },
        error => {
          console.log(<any>error)
        }
      );

    });
}

}

